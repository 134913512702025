import { useAuth } from '../../contexts/auth';
import Button from '../../ui/Button/Button';
import { loginButton } from './loginButton.css';

const LoginButton = () => {
  const { logOut, login, tokenData } = useAuth();

  return (
    <Button
      className={loginButton}
      label={tokenData ? 'logout' : 'login'}
      onClick={tokenData ? logOut : login}
    />
  );
};

export default LoginButton;
