import { Button as MantineButton, type ButtonProps } from '@mantine/core';

type Props = ButtonProps & {
  label: string;
  variant?: 'primary' | 'secondary';
  onClick: () => void;
};

const Button = ({ label, variant = 'primary', ...props }: Props) => {
  return (
    <MantineButton
      {...props}
      color={variant === 'primary' ? 'blue' : 'gray'}
      variant={variant === 'primary' ? 'filled' : 'outline'}
    >
      {label}
    </MantineButton>
  );
};

export default Button;
