import { useMemo } from 'react';
import ky from 'ky';

import { api } from '../api';
import { useAuth } from '../contexts/auth';
import { useConfig } from './useConfig';

const prefixUrl = '/api';

type ApiMethod = keyof ReturnType<typeof api>;

export const useApi = <T extends ApiMethod>(method: T) => {
  const { idToken, token } = useAuth();
  const { data } = useConfig();

  const apiToken = data?.useIdToken ? idToken : token;

  const apiClient = useMemo(
    () =>
      ky.create({
        prefixUrl,
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }),
    [apiToken],
  );

  return api(apiClient)[method];
};
