import { Code as MantineCode, type CodeProps } from '@mantine/core';

type Props = CodeProps & {
  content: string;
};

const Code = ({ content }: Props) => {
  return <MantineCode block>{content}</MantineCode>;
};

export default Code;
