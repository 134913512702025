import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/auth';

import { useApi } from './useApi';

export const useObjects = () => {
  const { token } = useAuth();
  const getObjects = useApi('getObjects');
  return useQuery({
    queryKey: ['objects'],
    queryFn: getObjects,
    staleTime: Infinity,
    enabled: !!token,
  });
};
