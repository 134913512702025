import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import { App } from '../App';
import ErrorPage from '../pages/ErrorPage';

// eslint-disable-next-line react-refresh/only-export-components
const CallbackHandler = () => {
  const { loginInProgress } = useAuth();
  if (loginInProgress) {
    return <div>Loading</div>;
  }
  return <Navigate replace to='/' />;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, path: '/', element: <App /> },
      { path: '/callback', element: <CallbackHandler /> },
    ],
  },
]);
