import { useContext } from 'react';
import type { ReactNode } from 'react';
import { AuthContext, AuthProvider as OAuth2Provider } from 'react-oauth2-code-pkce';
import type { TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';

import { useConfig } from '../hooks/useConfig';

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  if (context.tokenData && new Date(context.tokenData.exp * 1000) < new Date()) {
    return { ...context, token: undefined, tokenData: undefined };
  }

  return context;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading } = useConfig();

  if (!data || isLoading) {
    return <div>Loading</div>;
  }

  const { tokenEndpoint, signInUrl, clientId, scope } = data;

  const authConfig: TAuthConfig = {
    preLogin: () => {
      window.localStorage.returnUri = window.location.href;
    },
    postLogin: () => {
      const returnUri = window.localStorage.returnUri;
      if (returnUri) {
        delete window.localStorage.returnUri;
        window.location = returnUri;
      }
    },
    clientId,
    authorizationEndpoint: signInUrl,
    tokenEndpoint,
    redirectUri: `${window.location.origin}/callback`,
    autoLogin: false,
    clearURL: false,
    scope,
    onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
      window.confirm('Session expired. Refresh page to continue using the site?') && event.login(),
  };

  return <OAuth2Provider authConfig={authConfig}>{children}</OAuth2Provider>;
};

export default AuthProvider;
