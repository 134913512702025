import '@mantine/core/styles.css';
import './theme/global.css';

import { useAuth } from './contexts/auth';
import { useWhoami } from './hooks/useWhoami';
import { useObjects } from './hooks/useObjects';
import { Stack, Title, Text } from '@mantine/core';
import ColorSchemeToggle from './components/ColorSchemeToggle/ColorSchemeToggle';
import LoginButton from './components/LoginButton/LoginButton';
import { appContainer } from './app.css';
import Code from './ui/Code/Code';

export const App = () => {
  const { tokenData } = useAuth();
  const { data: whoami } = useWhoami();
  const { data: objects } = useObjects();

  return (
    <Stack justify='center' align='center' className={appContainer}>
      <ColorSchemeToggle />
      <Title>Express + React + Typescript + OpenTofu + Neon Postgresql + AWS</Title>
      {tokenData && (
        <>
          <Text>Current object data:</Text>
          <Code content={JSON.stringify(objects, null, 4)} />
          <Text>Current user data:</Text>
          <Code content={JSON.stringify(whoami, null, 4)} />
        </>
      )}
      <LoginButton />
    </Stack>
  );
};
