import { createTheme } from '@mantine/core';
import { themeToVars } from '@mantine/vanilla-extract';

const theme = createTheme({
  /** Put your mantine theme override here */
  primaryShade: 5,
  primaryColor: 'blue',
  fontFamily: 'Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Greycliff CF, sans-serif' },
});

export const vars = themeToVars(theme);
export default theme;
