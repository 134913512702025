import { useMantineColorScheme, UnstyledButton } from '@mantine/core';
import { colorSchemeToggle } from './colorSchemeToggle.css.ts';
import { IconMoon, IconSun } from '@tabler/icons-react';

const ColorSchemeToggle = () => {
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const handleChangeColorScheme = () => {
    setColorScheme(colorScheme === 'light' ? 'dark' : 'light');
  };

  const icon = colorScheme === 'light' ? <IconMoon /> : <IconSun />;

  return (
    <UnstyledButton onClick={handleChangeColorScheme} className={colorSchemeToggle}>
      {icon}
    </UnstyledButton>
  );
};

export default ColorSchemeToggle;
