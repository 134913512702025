import { Stack, Title, Text } from '@mantine/core';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { errorPage } from './index.css';

const ErrorPage = () => {
  const error = useRouteError();

  return (
    <Stack justify='center' align='center' className={errorPage}>
      <Title>Something went wrong!</Title>
      {isRouteErrorResponse(error) && <Text>{`${error.statusText} (${error.status})`}</Text>}
    </Stack>
  );
};

export default ErrorPage;
